<template>
    <div class="online">
        <use-sub-title title="点击问题 在线解答" :isSubTitle="false" subTitle="报考问题人工解答清晰了解考试信息"
            line-width="80px"></use-sub-title>
        <div class="online_body center_body">
            <ul class="online_list" @click="useCustomerService">
                <!-- :style="{ transform: `translateX(${onlineTrans}px)` }" -->
                <li>
                    <p v-for="(item, index) in onlineList.one" :key="index" class="online_li">{{ item }}</p>
                </li>
                <li>
                    <p v-for="(item, index) in onlineList.two" :key="index" class="online_li">{{ item }}</p>
                </li>
                <li>
                    <p v-for="(item, index) in onlineList.three" :key="index" class="online_li">{{ item }}</p>
                </li>
            </ul>
            <ul class="online_list">
                <li>
                    <p v-for="(item, index) in onlineList.one" :key="index" class="online_li">{{ item }}</p>
                </li>
                <li>
                    <p v-for="(item, index) in onlineList.two" :key="index" class="online_li">{{ item }}</p>
                </li>
                <li>
                    <p v-for="(item, index) in onlineList.three" :key="index" class="online_li">{{ item }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            onlineList: {
                one: ['小语种日语课程怎么样？', '五十音学习有技巧吗？', '日语零基础怎么学？', '日语学习难不难？', '学习日语有什么用？', '日语怎么入门？', '有日语试听课吗？', '日语网课哪家好', '考N1需要多久？', '报名有什么优惠吗？'],
                two: ['日语怎么入门？', '有日语试听课吗？', '考N1需要多久？', '报名有什么优惠吗？', '五十音学习有技巧吗？', '日语网课哪家好', '小语种日语课程怎么样？', '日语零基础怎么学？', '日语学习难不难？', '学习日语有什么用',],
                three: ['日语零基础怎么学？', '日语学习难不难？', '学习日语有什么用？', '有日语试听课吗？', '日语网课哪家好', '学习日语有什么用？', '日语怎么入门？', '五十音学习有技巧吗？', '小语种日语课程怎么样？', '考N1需要多久？'],
            },
        }
    },
    methods: {
        useCustomerService() {
            window.$openSwt.open();
        }
    }
}
</script>

<style scoped lang="scss">
@keyframes online_trans {
  0% {
    transform: translateX(0px);
  }
  // 50% {
  //   transform: translateX(-1500px);
  // }
  100% {
    transform: translateX(-2165.92px);
  }
}
// 在线答疑
.online {
    padding-bottom: 60px;

    .online_body {
        width: 1200px !important;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        overflow: hidden;

        .online_list {
            animation: online_trans 50s infinite linear;

            li {
                display: flex;
                margin-bottom: 30px;

                .online_li {
                    color: #0096ff;
                    background-color: #fff;
                    border: 1px solid #0096ff;
                    cursor: pointer;
                    margin-right: 20px;
                    padding: 0 30px;
                    min-width: 100px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 20px;
                    text-align: center;
                    white-space: nowrap;
                }

                .online_li:hover {
                    background: linear-gradient(90deg, #004cff, #0096ff);
                    color: #fff;
                }
            }
        }
    }
}
</style>