import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routers.js'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
let newArr = routes.map(ele => ele.name);
router.beforeEach((to, from, next) => {
  console.log(to, newArr)
  if (newArr.includes(to.name)) {
    next();
  }else {
    next('/')
  }
  
})
export default router
