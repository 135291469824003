<template>
  <div class="having-view">
    <use-sub-title title="专属方案 专业师资 贴心服务" :isSubTitle="false"></use-sub-title>
    <div class="having_title centerBody">
        <div class="left">
            <img src="" alt="">
        </div>
        <ul class="having_ul">
            <li>
                <p class="hav_title">教研师资</p>
                <el-rate class="rate" v-model="fen" disabled :colors="['#ea4384', '#ea4384', '#ea4384']" />
                <span>五大流程严格选拔</span>
                <span>中日双师执教</span>
                <span>日本留学工作经验</span>
                <span>平均5年以上教学经验</span>
                <span>学历、专业精准匹配</span>
                <p class="biaoti bb1">细致+负责</p>
            </li>
            <li>
                <p class="hav_title">答疑老师</p>
                <el-rate class="rate" v-model="fen" disabled :colors="['#ffbf00', '#ffbf00', '#ffbf00']" />
                <span>在线2对1辅导答疑</span>
                <span>巩固强化不留疑惑</span>
                <span>日语学习辅助资料产出</span>
                <!-- <span>日本留学咨询指导</span> -->
                <span>日语学习方法指导分享</span>
                <p class="biaoti bb2">耐心+敬业</p>
            </li>
            <li>
                <p class="hav_title">授课老师</p>
                <el-rate class="rate" v-model="fen" disabled :colors="['#64ade6', '#64ade6', '#64ade6']" />
                <!-- <span>进行课程规划</span> -->
                <span>授课技巧 娴熟精通</span>
                <span>实战讲练 科学规划</span>
                <span>学习课堂 生动有趣</span>
                <span>直播 + 录播相结合</span>
                <span>全方位辅导、听说读写全面开花</span>
                <p class="biaoti bb3">专业+博学</p>
            </li>
            <li>
                <p class="hav_title">辅导老师</p>
                <el-rate class="rate" v-model="fen" disabled :colors="['yellowgreen', 'yellowgreen', 'yellowgreen']" />
                <!-- <span>了解课程全貌</span>
                <span>进行课程规划</span> -->
                <!-- <span>在线督学</span> -->
                <span>匹配学员自身情况</span>
                <span>制定学习计划</span>
                <span>灵活调整学习进度</span>
                <span>在线伴学督学</span>
                <span>教研核心课程资料产出</span>
                <p class="biaoti bb4">亲和+严谨</p>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            fen: 5
        }
    }
}
</script>

<style lang="scss" scoped>
.having-view {
    margin: 30px 0;
    .having_title {
        .having_ul {
            // width: 850px;
            margin: 0 auto;
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            li {
                transition: all 0.2s linear;
                width: 262px;
                height: 345px;
                border-radius: 20px;
                
                position: relative;
                .hav_title {
                    font-size: 26px;
                    font-weight: bold;
                    color: #515151;
                    line-height: 40px;
                    text-align: center;
                    margin-top: 50px;
                }
                .rate {
                    width: 120px;
                    display: block;
                    margin-left: 51%;
                    transform: translateX(-50%);
                }
                span {
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    color: #333;
                    line-height: 28px;
                }
                .biaoti {
                    position: absolute;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 18px;
                    font-weight: 500;
                }
                .bb1 {
                    color: #f45b8c;
                    // color: #ffe100;
                }
                .bb2 {
                    // color: #d6001a;
                    color: #ffbf00;
                }
                .bb3 {
                    color: #64ade6;
                }
                .bb4 {
                    color: yellowgreen;
                }
            }
            li:hover {
                transform: translateY(-10px);
            }
            li:nth-child(1) {
                box-shadow: 0 0 15px #df267c;
            }
            li:nth-child(2) {
                box-shadow: 0 0 15px #ffbf00;
            }
            li:nth-child(3) {
                box-shadow: 0 0 15px #68a0e3;
            }
            li:nth-child(4) {
                box-shadow: 0 0 15px yellowgreen;
            }
        }
    }
}
</style>