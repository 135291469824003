<template>
  <div class="header">
    <div class="topAdvAreaWrap" v-show="isTop">
      <div class="sideForms" @click="useCustomerService"></div>
      <!-- <div class="top_adv_close" @click="closeTop">
        <img src="@/assets/home/关闭.png" alt="">
      </div> -->
    </div>
    <div class="topNav">
      <div class="overflowCenter">
        <img src="../assets/handu_logo.png" alt="" class="logo" />
        <!-- <span> | 专注上岸的网校 </span> -->
        <ul class="navList">
          <li v-for="(item, index) in navList" :key="index" @click="navBtn(item)"
            :class="[item.name == route.name ? 'active' : '']">
            {{ item.title }}
          </li>
        </ul>
        <p> <img src="@/assets/image/phone.png" class="phone" alt=""><span>400-7800-118</span></p>
        <!-- <p>服务热线：<span>400-7800-118</span></p> -->
       
      </div>
    </div>
    <!-- <img src="//30594067.s21i.faimallusr.com/2/ABUIABACGAAg-IjWnwYozMelnAcwgA842AQ.jpg" alt=""
      class="homeBanner" @click="useCustomerService" /> -->
    <!-- <img src="@/assets/home/bannerOne.png" class="homeBanner" @click="useCustomerService" alt=""> -->
  </div>
</template>

<script setup>
import { navList } from "../api/navList";
import { useCustomerService } from '@/api/common.js';
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
const isTop = ref(false)

// const closeTop = () => {
//   isTop.value = false;
// }

const router = useRouter();
const route = useRoute();
console.log(route, 'route')
function scrollToTop() {
  const currentPosition = document.documentElement.scrollTop;
  if (currentPosition > 0) {
    window.scrollTo(0, 0)
    // window.requestAnimationFrame(scrollToTop);
    // window.scrollTo(0, currentPosition - currentPosition / 10);
  }
}
scrollToTop();
const navBtn = (item) => {
  router.push(item.path)
  scrollToTop();
  // window.open(item.path, '_blank')
};
</script>

<style scoped lang="scss">
.active {
  color: #0096ff;
  font-weight: bold;
  font-size: 20px;
}

.header {
  min-width: 1250px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  .topAdvAreaWrap {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: url("../assets/topBgc.png") no-repeat 0 0;

    .sideForms {
      width: 1200px;
      height: 85px;
    }

    .top_adv_close {
      z-index: 8;
      position: absolute;
      top: 24px;
      right: 65px;
      width: 35px;
      height: 35px;
      cursor: pointer;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .topNav {
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
  }

  .overflowCenter {
    width: 1200px;
    // background: red;
    // margin-left: 267px;
    // margin-left: 287px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 200px;
      height: 60px;
    }

    span {
      // color: rgb(255, 235, 60);
      color: #0096ff;
      font-weight: 500;
      line-height: 85px;
      padding-left: -20px;
      cursor: default;
    }

    ul {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      li {
        width: 100px;
        margin: 0 0px;
        text-align: center;
        cursor: pointer;
      }
    }

    P {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    .phone {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      // margin-top: 15px;
    }
  }
}
</style>
