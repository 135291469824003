<template>
    <div class="teacher-superiority">
        <img class="title_img" src="@/assets/handu/laoshi_title.png" alt="">
        <!-- <use-sub-title title="老师收入与学员上岸挂钩" :isSubTitle="false" subTitle="每位老师都是全力协助您快速上岸" class="useSubTitle"></use-sub-title> -->
        <div class="content">
            <div class="left">
                <p>汉都教育</p>
                <ul class="leftContent rightContent">
                    <li v-for="(item, index) in superList" :key="index" :style="tranStyle(index)">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <img class="center_img" src="@/assets/handu/juxing.png" alt="">
            <div class="right">
                <p>其它机构</p>
                <ul class="rightContent">
                    <li v-for="(item, index) in otherList" :key="index" :style="tranStyle(index)">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
        <!-- <use-button>
            立即咨询
        </use-button> -->
    </div>
</template>

<script setup>
import { reactive } from "vue";

const superList = reactive(['匹配学员自身情况制定专属学习计划', '每年限额招生，确保上岸率', '小班制教学，一位班主任老师带3-5人', '真人露脸直播课程为主+回放补习', '课后专属辅导答疑+在线纠音' , '突出重难点分层教学+单元总结拓展+阶段复习测验']);
const otherList = reactive(['统一录制课程面向所有考生', '收人越多越好，只关注利润', '大班制授课', '录播授课为主，直播为辅', '建立微信大群，不及时解决疑问' , '重难点模糊，按照书本照本宣科']);

const tranStyle = () => {
    return ''
    // return `transform: translateX(${'-' + index * 15 + 'px'})`
};
</script>

<style lang="scss" scoped>
.teacher-superiority {
    padding-top: 30px;
    width: 1903px;
    padding-bottom: 120px;
    background: url('@/assets/handu/bgc.png') 100% 100%;

    .content {
        width: 1200px;
        height: 402px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .left {
            p {
                text-align: center;
                font-weight: bold;
                font-size: 30px;
                margin: 20px auto;
                color: #7276fc;
                line-height: 40px;
                // color: linear-gradient(to right, #7276fc, #d15385);
            }
        }

        .center_img {
            margin-right: 40px;
            margin-top: 30px;
            height: 430px;
        }

        .right {
            margin-right: 50px;
            p {
                text-align: center;
                font-weight: bold;
                margin: 20px auto;
                font-size: 26px;
                line-height: 40px;
            }
        }
    }
}

.title_img {
    width: 800px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    margin-bottom: -40px;
}

.leftContent {
    li {
        color: #ef989b;
    }
}

.rightContent,
.leftContent {
    li {
        font-weight: bold;
        font-size: 22px;
        line-height: 60px;
        border-bottom: 1px solid #d9d9d9;
        text-align: center;
    }
}
</style>