<template>
  <div class="imageText">
    <div class="topHeader">
      <div class="content">
        <img src="@/assets/image/logos.png" alt="" class="imageLogo">
        <p class="title">{{ props.imageTextOgj.title }}</p>
      </div>
      <ul class="subNav">
        <li v-for="(item, index) in props.imageTextOgj.navList" :key="index"
          :class="[is_active == index ? 'li_active' : '']" @mouseenter="useEnter(item, index)">{{ item.title }}
        </li>
        <label>|</label>
      </ul>
    </div>
    <div class="centerBody">
      <div class="left">
        <img v-for="(item, index) in props.imageTextOgj[enterData].imgUrlList" :key="index"
          :src="require(`@/assets/image/${item}`)" @click="useCustomerService" alt="">
      </div>
      <div class="right">
        <div class="subTitleBody" v-for="(item, index) in props.imageTextOgj[enterData].textWord" :key="index">
          <div class="subTitle">
            <h3>{{ item.subTitle }}</h3>
            <p @click="useCustomerService">更多>></p>
          </div>
          <ul class="subList">
            <li v-for="(ele, indexs) in item.textList" :key="indexs" @click="textButton(ele)">
              <p class="listText">{{ ele.text }}</p>
            </li>
          </ul>
        </div>
        <div style="clear:both"></div>
        <div class="bottomNav">
          <img src="@/assets/home/botNav.png" @click="useCustomerService" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCustomerService, useContentDetail } from '@/api/common';
import { ref } from 'vue';

const props = defineProps(['imageTextOgj']);
// const preData = toRefs(props.imageTextOgj.navList)


// 数据的修改与默认值
const enterData = ref(props.imageTextOgj.navList[0].tag)
const is_active = ref(0);
const useEnter = (item, index) => {
  is_active.value = index
  enterData.value = item.tag
};

const textButton = (ele) => {
  if (ele.params.indexOf('Student') != -1) {
    useCustomerService()
  } else {
    useContentDetail(ele)
  }
}

</script>

<style lang="scss" scoped>
.imageText {
  height: 596px;
  border: 1px solid #dcdcdc;
  margin: 20px 0 10px 0;

  .topHeader {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;

    height: 66px;
    line-height: 66px;

    .content {
      display: flex;

      .imageLogo {
        width: 38px;
        height: 38px;
        margin: 14px 10px 20px 20px;
      }

      .title {
        font-size: 22px;
        letter-spacing: 2px;
        color: #666666;
      }
    }

    .subNav {
      display: flex;
      font-size: 22px;
      color: #0b0b0b;
      position: relative;

      label {
        position: absolute;
        left: 50%;
        font-size: 14px;
        color: #999;
      }

      li {
        line-height: 66px;
        cursor: pointer;
        margin: 0 15px;
        padding: 0 15px;
      }

      .li_active {
        border-bottom: 2px solid #1e72e7;
      }
    }
  }

  .centerBody {
    width: 1160px;
    height: 480px;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .left {
      width: 500px;

      img {
        width: 246px;
        height: 148px;
        cursor: pointer;
      }

      img:nth-child(2) {
        margin-right: 8px;
      }

      img:first-child {
        width: 500px;
        height: 302px;
        margin-bottom: 4px;
        margin-left: 0;
      }
    }

    .right {
      width: 620px;

      .subTitleBody {
        min-width: 300px;
        width: 300px;
        float: left;

        .subTitle {
          color: #555555;
          display: flex;
          font-size: 18px;
          cursor: default;
          justify-content: space-between;
          border-bottom: 1px solid #dcdcdc;
          height: 42px;
          line-height: 42px;

          p {
            font-size: 13px;
            cursor: pointer;
            margin-top: 5px;
          }
        }

        .subList {
          margin: 20px 0 0 15px;

          li {
            list-style: disc;
            margin: 15px 0;
            color: #fb542f;
            font-size: 12px;
            height: 18px;
            line-height: 18px;
          }

          .listText {
            color: #333;
            cursor: pointer;
            font-size: 15px;
            letter-spacing: 0.5px;
          }

          .listText:hover {
            font-size: 16px;
            color: #fb542f;
          }
        }

      }

      .subTitleBody:first-child {
        margin-right: 20px;
      }

      .subTitleBody:nth-child(3) {
        width: 620px;
        margin-top: 7px;

        .listText:hover {
          font-size: 15px;
          color: #333;
        }
      }

      .bottomNav {
        img {
          cursor: pointer;
        }
      }
    }
  }

  .v-enter-from {
    opacity: 0;
  }

  .v-enter-active {
    transition: opacity 1s;
  }

  .v-enter-to {
    opacity: 1;
  }
}
</style>