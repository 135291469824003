<template>
    <div class="newCurriculumForm">
        <div class="centerBody">
            <h2 class="title">快速提分 <span>有效复习</span></h2>
            <ul class="form_body">
                <li v-for="(item, index) in dataList" :key="index">
                    <dl>
                        <dt>
                            <div class="image"></div>
                            <!-- <img :src="require(`@/assets/newCurriculumForm/${item.imgUrl}`)" alt=""> -->
                        </dt>
                        <dd>
                            <p class="title">
                                {{ item.title }}
                            </p>
                            <p class="text">
                                {{ item.text }}
                            </p>
                            <p class="button">
                                立即咨询
                            </p>
                        </dd>
                    </dl>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataList: [
                {
                    imgUrl: "one.png",
                    title: "基础课程",
                    text: "系统学习高考日语基础知识覆盖考纲所有必考考点"
                },
                {
                    imgUrl: "two.png",
                    title: "进阶课程",
                    text: "通过词汇、阅读翻译、语法、写作等专题课强化高考日语专项能力"
                },
                {
                    imgUrl: "three.png",
                    title: "专项训练",
                    text: "通过知识总结梳理、真题训练查缺补漏，对薄弱环节进行提升"
                },
                {
                    imgUrl: "four.png",
                    title: "考前冲刺",
                    text: "针对新题型和写作、阅读、翻译等总结答题技巧等，提高应试能力"
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
.newCurriculumForm {
    background: #fdf4ef;
    padding-bottom: 70px;
}

.title {
    text-align: center;
    padding-top: 38px;
    font-size: 45px;

    span {
        background-image: -webkit-linear-gradient(right, #6fc4c0, #0096ff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.form_body {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    li {
        width: 270px;
        height: 370px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        background: url("@/assets/newCurriculumForm/bgc.png") #fff no-repeat right bottom;
    }

    dl {
        dt {
            width: 94px;
            height: 94px;
            padding: 20px;
            border-radius: 50%;
            margin: 25px auto 15px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
        }

        dd {
            .title {
                font-size: 20px;
                color: #72a0f6;
                text-align: center;
                padding: 10px 0 5px 0;
                font-weight: bold;
                text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
            }
            .text {
                width: 90%;
                margin: 15px auto;
                font-size: 15px;
                line-height: 180%;
                color: #666;
                text-align: justify;
                text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
            }
            .button {
                width: 90%;
                margin: 20px auto 10px auto;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #fff;
                background: #72a0f6;
                border-radius: 5px;
            }
        }
    }

    li:hover {
        dt {
            background: #3e83ff;
           
        }
        dd {
            .title {
                color: #000;
            }
            .button {
                background: #516cff;
            }
        }
    }

    li:nth-child(1) {
        .image {
            width: 100%;
            height: 100%;
            background: url("@/assets/newCurriculumForm/one.png") no-repeat;
        }
    }

    li:nth-child(2) {
        .image {
            width: 100%;
            height: 100%;
            background: url("@/assets/newCurriculumForm/two.png") no-repeat;
        }
    }

    li:nth-child(3) {
        .image {
            width: 100%;
            height: 100%;
            background: url("@/assets/newCurriculumForm/three.png") no-repeat;
        }
    }

    li:nth-child(4) {
        .image {
            width: 100%;
            height: 100%;
            background: url("@/assets/newCurriculumForm/four.png") no-repeat;
        }
    }

    li:nth-child(1):hover {
        .image {
            background: url("@/assets/newCurriculumForm/one-active.png") no-repeat;
        }
    }

    li:nth-child(2):hover {
        .image {
            background: url("@/assets/newCurriculumForm/two-active.png") no-repeat;
        }
    }

    li:nth-child(3):hover {
        .image {
            background: url("@/assets/newCurriculumForm/three-active.png") no-repeat;
        }
    }

    li:nth-child(4):hover {
        .image {
            background: url("@/assets/newCurriculumForm/four-active.png") no-repeat;
        }
    }
}
</style>