<template>
    <div class="useNavList">
        <div class="countTitle">
            <p class="title" v-for="(item, index) in props.navObj.titleList" :key="index" @click="useContentDetail(item)">{{ item.title
            }}</p>
        </div>
        <ul>
            <li v-for="(item, index) in props.navObj.subNav" :key="index">
                <label>{{ item.subTitle }}</label>
                |<span v-for="(ele, indexs) in item.children" :key="indexs" @click="useContentDetail(ele)">
                    {{ ele.title }}
                </span>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useContentDetail } from '@/api/common.js'
const props = defineProps({
    navObj: Object,
});
</script>

<style lang="scss">
.useNavList {
    .countTitle {
        display: flex;
        margin-bottom: 12px;

        p {
            font: bold 17px "Microsoft Yahei";
            color: #e60412;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    ul {
        width: 370px;
        border-bottom: 1px dashed #666666;

        li {
            font-size: 13px;
            width: 370px;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            color: #666666;

            label {
                margin-right: 5px;
            }

            span {
                cursor: pointer;
                margin-right: 5px;
            }
        }

        li:first-child {
            label {
                color: #e60412;
            }

            span {
                color: #e60412;
            }
        }

        li:last-child {
            margin-bottom: 10px;
        }
    }
}

.noneBottom {
    .title {
        margin-top: 10px;
    }

    ul {
        border: none;
    }
}
</style>